@import url('https://fonts.googleapis.com/css2?family=Bungee+Inline&display=swap');

.Nav {
  text-align: center;
  font-family: 'Bungee Inline', cursive;

  .NavItem {
    a { text-decoration: none; }
    width: 0;
    height: 0;
    border: 50px solid transparent;
    border-bottom: 80px solid black;
    position: relative;
    top: -40px;
    display: inline-block;
    vertical-align: center;
    .NavItem-Text {
      position: absolute;
      top: 55px;
      left: -51px;
      z-index: 100;
      text-align: center;
      vertical-align: middle;
      width: 99px;
      padding: 0 12px;
      height: 80px;
      color: white;
      &.V1 { top: 40px; }
      &.V2 { top: 65px; }
    }
    .Notification {
      position: absolute;
      top: 30px;
      left: -10px;
      color: white;
      background-color: #cdac00;
      border-radius: 100px;
      width: 20px; height: 20px;
    }

    &:after {
      content: '';
      position: absolute;
      left: -50px;
      top: 80px;
      width: 0;
      height: 0;
      border: 50px solid transparent;
      border-top: 80px solid black;
    }
    &:nth-of-type(3n) {
      border-bottom: 80px solid rgba(0,0,0,0.8);
      &:after {
        border-top: 80px solid rgba(0,0,0,0.8);
      }
    }
    &:nth-of-type(3n -1) {
      border-bottom: 80px solid rgba(0,0,0,0.6);
      &:after {
        border-top: 80px solid rgba(0,0,0,0.6);
      }
    }
  }

  position: relative;
  .NavImg {
    width: 800px;
    max-width: 90%;
    top: -20px;
    position: absolute;
    z-index: -10;
  }
}
