@import url('https://fonts.googleapis.com/css2?family=Bitter:wght@700&display=swap');
$dark: #2e2e2e;
.Page {
  padding: 0;
  .Nav {
    margin: 0 auto;
  }
  &.Page-WithNav {
  }
  .BannerImage {
    background-repeat: no-repeat;
    margin: 0 auto;
    margin-top: 40px;
    display: block;
    position: relative;
    img {
      width: 100%;
    }
    .BannerImage-Title {
      position: absolute;
      top: 1.5vw;
      width: 100%;
      text-align: center;
      color: white;
      font-size: 5vw;
      font-family: 'Bitter', serif;
    }
  }
  .FooterImage {
    max-width: 800px;
    margin: 150px auto 0 auto;
    img {
      max-width: 800px;
      max-width: 100%;
    }

  }
}

.OurWedding-Img{
  margin: 200px auto;
  display: block;
  max-width: 1500px;
  width: 100%;
  &:last-of-type {
    max-width: 1400px;
    margin-bottom: 50px;
  }
}

.Page-Home {
  .Img{
    margin: 200px auto;
    display: block;
    max-width: 100%;
    &:last-of-type {
      margin-bottom: 50px;
    }
  }
  .Div-1 {
    margin: 200px auto;
    text-align: center;
    font-size: 48px;
    font-weight: bold;
    font-family: 'Bitter', serif;
  }
  .LiveStream {
    max-width: 100%;
    text-align: center;
    margin: 200px auto 40px auto;
    font-family: 'Bungee Inline', cursive;
    position: relative;
    img {
      max-width: 100%;
      position: absolute; left: 0;
      z-index: -1;
      top: 0;
      @media only screen and (max-width: 768px) {
        top: 80px;
      }
    }
    .Links {
      margin-top: 60px;
      text-align: center;
      width: 100%;

      a {
        color: black;
        font-size: 30px;
        display: block;
        .Time {
          margin-top: 5px;
          font-size: 20px;
        }
      }
      .Wedding {
        margin-bottom: 60px;
      }
    }
  }
}
@import url('https://fonts.googleapis.com/css2?family=Herr+Von+Muellerhoff&display=swap');
.People {
  margin: 200px auto;
  max-width: 1200px;
  text-align: center;
  .Person {
    vertical-align: top;
    width: 250px;
    position: relative;
    text-align: center;
    display: inline-block;

    margin: 30px 25px;

    .Image {
      width: 150px;
      border-radius: 100px;
      text-align: center;
      filter: grayscale(100%);
    }

    h3 {
      margin: 50px 0px 5px 0px;
      font-weight: 400;
      font-size: 48px;
      font-family: 'Herr Von Muellerhoff', cursive;
    }

    .Decoration {
      width: 295px;
      height: 240px;
      position: absolute;
      top: -40px;
      left: -30px;
    }

    h4 {
      margin: -10px 0 5px 0px;
      font-weight: 300;
      font-size: 16px;
      text-align: right;
      padding: 0 24px;
    }

    .Description {
      color: #c7c7c7;
      font-size: 14px;
      text-align: left;
    }
  }
}

.Page-Registry {
  .Registry {
    margin: 300px auto;
    max-width: 1050px;
    min-height: 700px;
    text-align: center;
    position: relative;
    .Flower {
      position: absolute;
      left: calc(50% - 200px);
      height: 120%;
      transform: rotate(11deg);
      z-index: 1;
      bottom: -10px;
    }
    .R {
      width: 50%;
      min-height: 700px;
      display: inline-block;
      background-position: center;
    }
    .R1 {
      position: relative;
      background-color: $dark;

      a {
        z-index: 10;
        width: 100%;
        font-size: 64px;;
        color: white;
        font-weight: bold;
        position: absolute;
        text-align: center;
        text-decoration: none;
        &:hover { text-decoration: underline;; }
        left: 0;
        &.Amazon {
          top: 180px;
        }
        &.CB {
          top: 370px;
        }
      }
    }
  }
}

.Page-Updates {
  .Div {
    &.Div-1 { margin-top: 200px; }
    margin: 100px auto;
    text-align: center;
    font-size: 36px;
    font-weight: bold;
    max-width: 700px;
    text-align: justify;
    &.Div-5 { max-width: 440px; text-align: center; }
    @media only screen and (max-width: 768px) {
      text-align: left;
      margin: 100px 25px;
    }
  }
  .Img-1 {
    max-width: 200px;
    margin: 0 auto;
    display: block;
  }
}

.Page-Accomodations {
  .Accomodations-Img{
    margin: 200px auto;
    display: block;
    max-width: 100%;
    &:last-of-type {
      margin-bottom: 50px;
    }
  }
}

.Page-Weekend {
  .Weekend-Img{
    margin: 200px auto;
    display: block;
    max-width: 100%;
    &:last-of-type {
      margin-bottom: 50px;
    }
  }
  .Eats {
    margin: 20px auto;
    max-width: 800px;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    position: relative;
    z-index: 1;
    .Eats-Img {
      position: absolute;
      right: 50px;
      bottom: 0;
      transform: rotate(-15deg);
      width: 200px;
      z-index: -1;
    }
  }
}

.Page-Our-Story {
  .P {
    &.P1 { margin-top: 200px; }
    margin: 25px auto;
    font-size: 22px;
    font-family: 'Bitter', serif;
    max-width: 60%;
    text-align: justify;
  }
  .ImageDiv {
    margin: 100px auto;
    max-width: 90%;
    position: relative;

    .ImageBorder {
      width: 8vw;
      height: 8vw;
      position: absolute;

      &.ImageBorder-1 {
        bottom: calc(100% - 6vw);
        right: calc(100% - 6vw);
        transform: rotate(180deg);
      }

      &.ImageBorder-2 {
        top: calc(100% - 6vw);
        left: calc(100% - 6vw);
      }
    }
    &:nth-of-type(2n) {
      .ImageBorder {
        &.ImageBorder-1 {
          top: calc(100% - 6vw);
          right: calc(100% - 6vw);
          transform: rotate(90deg);
        }

        &.ImageBorder-2 {
          top: auto;
          bottom: calc(100% - 6vw);
          left: calc(100% - 6vw);
          transform: rotate(-90deg);
        }
      }
    }
    .OurStory-Img {
      width: 100%;
      filter: grayscale(100%);

      &.Top { margin-bottom: 0; }
      &.Bottom { margin-top: -10px; }
    }
  }
}
