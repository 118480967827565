@import url('https://fonts.googleapis.com/css2?family=Gilda+Display&display=swap');

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Roboto', sans-serif;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: 'Gilda Display', serif;
}

* { box-sizing: border-box; }
